@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.z-10 {
  z-index: 10;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.h-36 {
  height: 9rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.grow {
  flex-grow: 1;
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-4 {
  gap: 1rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-charcoal\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: #37373799;
}

.justify-self-end {
  justify-self: end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-trbr {
  border-radius: 0 15px;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-greengold\/20 {
  border-color: #7b6a3833;
}

.border-lighthoney {
  --tw-border-opacity: 1;
  border-color: rgb(255 230 188 / var(--tw-border-opacity));
}

.bg-darkhoney {
  --tw-bg-opacity: 1;
  background-color: rgb(255 203 116 / var(--tw-bg-opacity));
}

.bg-darkhoney\/20 {
  background-color: #ffcb7433;
}

.bg-greenblack {
  --tw-bg-opacity: 1;
  background-color: rgb(48 42 24 / var(--tw-bg-opacity));
}

.bg-greengold {
  --tw-bg-opacity: 1;
  background-color: rgb(123 106 56 / var(--tw-bg-opacity));
}

.bg-greengold\/20 {
  background-color: #7b6a3833;
}

.bg-paper {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-clip-content {
  background-clip: content-box;
}

.p-1 {
  padding: .25rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[44px\] {
  line-height: 44px;
}

.text-blackish {
  --tw-text-opacity: 1;
  color: rgb(28 28 28 / var(--tw-text-opacity));
}

.text-charcoal\/60 {
  color: #37373799;
}

.text-lighthoney {
  --tw-text-opacity: 1;
  color: rgb(255 230 188 / var(--tw-text-opacity));
}

.text-paper {
  --tw-text-opacity: 1;
  color: rgb(246 246 246 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-star {
  --tw-shadow: 0px 0px 0px 0px #0003, 0px 0px 0px 0px #00000030, 0px 0px 0px 0px #0000002b, 0px 1px 1px 0px #0000001a, 0px 2px 1px 0px #00000008, 0px 3px 1px 0px #0000;
  --tw-shadow-colored: 0px 0px 0px 0px var(--tw-shadow-color), 0px 0px 0px 0px var(--tw-shadow-color), 0px 0px 0px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color), 0px 2px 1px 0px var(--tw-shadow-color), 0px 3px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-darkhoney\/60 {
  --tw-ring-color: #ffcb7499;
}

.drop-shadow-pretty {
  --tw-drop-shadow: drop-shadow(0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:root {
  --blackish: #1c1c1c;
  --greenblack: #302a18;
  --greengold: #7b6a38;
  --paper: #f6f6f6;
  --charcoal: #373737;
  --paper-gray: #f2f2f2;
  --butter: #fde4ce;
  --darkhoney: #ffcb74;
  --lighthoney: #ffe6bc;
  --amber: #c79746;
  --linkblue: #517e82;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

* {
  box-sizing: border-box;
}

main {
  font-family: var(--dmsans);
  color: var(--blackish);
}

h1 {
  font-family: var(--poppins);
  letter-spacing: -.45px;
  font-size: 57px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
}

h2 {
  color: var(--green-black, #1c1c1c);
  font-family: var(--poppins);
  letter-spacing: -.09px;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
}

h3 {
  font-family: var(--poppins);
  color: var(--green-black, #1c1c1c);
  font-weight: 400;
  line-height: 40px;
}

.navlink {
  --tw-text-opacity: 1;
  color: rgb(242 242 242 / var(--tw-text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.navlink:hover {
  --tw-text-opacity: 1;
  color: rgb(246 246 246 / var(--tw-text-opacity));
  text-underline-offset: 8px;
  text-decoration-line: underline;
}

.home-banner {
  justify-content: center;
  align-items: center;
  display: flex;
}

.banner-reverse {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
  --tw-shadow: 0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004d;
  --tw-shadow-colored: 0px 2px 6px 2px var(--tw-shadow-color), 0px 1px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 8px;
  border-color: #ffe6bc33;
  border-radius: 0 15px;
  flex-direction: column;
  grid-auto-rows: auto;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 1rem 1rem 2rem;
  display: flex;
}

.screen-padding {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (width >= 768px) {
  .screen-padding {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (width >= 1280px) {
  .screen-padding {
    padding-left: 14rem;
    padding-right: 14rem;
  }
}

.no-checkmark span.event-icon {
  display: none;
}

.p-detail {
  padding-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.h3-detail {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2;
}

.event-date-time, .event-location {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

.event-icon {
  --tw-text-opacity: 1;
  color: rgb(199 151 70 / var(--tw-text-opacity));
}

.attendee-tab-label {
  --tw-ring-color: rgb(246 246 246 / var(--tw-ring-opacity));
  --tw-ring-opacity: .6;
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #ffcb74;
  border-radius: .75rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.attendee-tab-label:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.attendee-tab-label-selected {
  --tw-bg-opacity: 1;
  background-color: rgb(255 203 116 / var(--tw-bg-opacity));
  --tw-shadow: 0px 0px 0px 0px #0003, 0px 0px 0px 0px #00000030, 0px 0px 0px 0px #0000002b, 0px 1px 1px 0px #0000001a, 0px 2px 1px 0px #00000008, 0px 3px 1px 0px #0000;
  --tw-shadow-colored: 0px 0px 0px 0px var(--tw-shadow-color), 0px 0px 0px 0px var(--tw-shadow-color), 0px 0px 0px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color), 0px 2px 1px 0px var(--tw-shadow-color), 0px 3px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
}

.person-line {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  width: 100%;
  padding: .5rem 1rem;
}

.person-grid {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: .25rem;
  margin-top: .25rem;
  font-weight: 400;
  display: grid;
}

.fa-icon {
  --tw-drop-shadow: drop-shadow(0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  background-clip: content-box;
}

.person-name {
  grid-column: span 10 / span 10;
}

.hover\:bg-darkhoney\/60:hover {
  background-color: #ffcb7499;
}

.hover\:bg-greengold\/60:hover {
  background-color: #7b6a3899;
}

.hover\:text-blackish:hover {
  --tw-text-opacity: 1;
  color: rgb(28 28 28 / var(--tw-text-opacity));
}

.focus\:shadow-star:focus {
  --tw-shadow: 0px 0px 0px 0px #0003, 0px 0px 0px 0px #00000030, 0px 0px 0px 0px #0000002b, 0px 1px 1px 0px #0000001a, 0px 2px 1px 0px #00000008, 0px 3px 1px 0px #0000;
  --tw-shadow-colored: 0px 0px 0px 0px var(--tw-shadow-color), 0px 0px 0px 0px var(--tw-shadow-color), 0px 0px 0px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color), 0px 2px 1px 0px var(--tw-shadow-color), 0px 3px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-darkhoney:focus {
  outline-color: #ffcb74;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-darkhoney:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 203 116 / var(--tw-ring-opacity));
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

@media (width >= 640px) {
  .sm\:gap-4 {
    gap: 1rem;
  }
}

@media (width >= 768px) {
  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:p-24 {
    padding: 6rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:text-\[57px\] {
    font-size: 57px;
  }

  .md\:leading-\[64px\] {
    line-height: 64px;
  }
}

@media (width >= 1024px) {
  .lg\:gap-16 {
    gap: 4rem;
  }
}
/*# sourceMappingURL=index.be93ae60.css.map */

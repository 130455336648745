@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --blackish: #1c1c1c;
  --greenblack: #302a18;
  --greengold: #7b6a38;
  --paper: #f6f6f6;
  --charcoal: #373737;
  --paper-gray: #f2f2f2;
  --butter: #fde4ce;
  --darkhoney: #ffcb74;
  --lighthoney: #ffe6bc;
  --amber: #c79746;
  --linkblue: #517e82;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

* {
  box-sizing: border-box;
}

main {
  font-family: var(--dmsans);
  color: var(--blackish);
}
h1 {
  /* M3/display/large */
  font-family: var(--poppins);
  font-size: 57px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px; /* 112.281% */
  letter-spacing: -0.45px;
}

h2 {
  color: var(--green-black, #1c1c1c);
  /* M3/display/small */
  font-family: var(--poppins);
  font-size: 36px;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.09px;
}

h3 {
  font-family: var(--poppins);
  font-weight: 400;
  line-height: 40px; /* 125% */
  color: var(--green-black, #1c1c1c);
}
.navlink {
  @apply font-semibold text-xl text-paper-gray 
  hover:underline hover:underline-offset-8  hover:text-paper
  px-4;
}

.home-banner {
  @apply flex items-center justify-center;
}
.banner-reverse {
  @apply bg-paper border border-lighthoney/20 border-8 rounded-trbr shadow-pretty
  px-4 pt-4 pb-8 -mx-5
  flex flex-col gap-1 auto-rows-auto justify-start items-start;
}

.screen-padding {
  @apply px-6 md:px-16 xl:px-56;
}

.no-checkmark span.event-icon {
  display: none;
}

.p-detail {
  @apply pb-4 text-lg
}

.h3-detail {
  @apply text-2xl font-medium leading-loose
}

/* date/time/location */

/* .event-details {
@apply 
px-4 pt-4
shadow-pretty
flex flex-col gap-1 auto-rows-auto justify-start items-center
} */

/* .event-banner {
@apply flex flex-col gap-1 auto-rows-auto justify-start items-start
} */

/* date/time */
.event-date-time, .event-location {
  @apply flex flex-row
  justify-start items-start gap-4
}

/* icons */
.event-icon {
  @apply text-amber
}

.attendee-tab-label {
  @apply w-full rounded px-4 rounded-xl
  text-sm font-medium leading-5 ring-paper ring-opacity-60 ring-offset-2 ring-offset-darkhoney focus:outline-none focus:ring-2
} 
.attendee-tab-label-selected {
  @apply bg-darkhoney shadow-star rounded-full
}

.person-line {
  @apply px-4 py-2 w-full shadow-sm
}

.person-grid {
  @apply mt-1 grid grid-cols-12 font-normal gap-1
}

.fa-icon {
  @apply bg-clip-content drop-shadow-pretty
}

.person-name {
  @apply col-span-10
}
